<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items v-if="nav.length > 0" :items="nav" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      nav: [],
    }
  },
  async created(){
    setTimeout(()=>{
      if(this.$store.getters['auth/userInfo'])
        this.nav = navMenuItems.filter(x => x.role == this.$store.getters['auth/userInfo'].role)
    },2000)
  },
  methods:{
    waitForData(){
      function waitForIt(){
        if (!this.$store.getters['auth/userInfo']) {
          setTimeout(function(){waitForIt()},100);
        } else {
          this.nav = navMenuItems.filter(x => x.role == this.$store.getters['auth/userInfo'].role)
        };
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
